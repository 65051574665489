import React from 'react';
import { Layout, Breakpoints, Text, styled, Container, ContainerGutter, CTA, TokenTextAppearance, } from '@volkswagen-onehub/components-core';

const PaddingWrapper = styled.div`
	padding-top: 32px;
`;

const TextColor = styled.span`
	color: #6a767d;
`;
interface LegalesCEMProps {
	isPosventa?: boolean;
}

export const LegalesCEM = (props: LegalesCEMProps) => {

	return (
		<Layout
			allowOverflowingContent
			appearance={{
				[Breakpoints.default]: [
					{ name: '.', columns: 2 },
					{ name: 'a', columns: 20 },
					{ name: '.', columns: 2 },
				],
			}}
		>
			<PaddingWrapper>
				<Container gutter={ContainerGutter.static200} wrap={'always'}>
					<Text bold appearance={TokenTextAppearance.copy200}>
						<TextColor>Texto legal de Volkswagen</TextColor>
					</Text>
					<Text appearance={TokenTextAppearance.copy100}>
						<TextColor>
							El responsable de tus datos es Volkswagen Group España Distribución, S.A.U. Vamos a utilizar tus datos para realizar un control de calidad sobre los productos y servicios contratados, mediante la realización de la presente encuesta de satisfacción, y contactarte para resolución de incidencias, reclamaciones o quejas. Tus datos podrán ser comunicados a las empresas del grupo para las finalidades de controlar la calidad de los productos y servicios, así como contactarte para resolución de incidencias, reclamaciones o quejas y, en el caso que hayas consentido, para elaborar tu perfil como usuario para enviarte comunicaciones comerciales según lo indicado en el consentimiento. También en el supuesto de haber consentido, tus datos y las respuestas de la presente encuesta de satisfacción serán comunicadas al {props.isPosventa ? 'Servicio Oficial' : 'punto de venta'}.
						</TextColor>
					</Text>
					<Text appearance={TokenTextAppearance.copy100}>
						<TextColor>
							Tienes derecho a oponerte al tratamiento, limitar el mismo, acceder, rectificar, suprimir los datos y ejercer tu derecho a portabilidad, mediante el envío de un correo electrónico a:{' '}
							<CTA tag="a" href="mailto:atencioncliente@volkswagen.es" target="_blank" emphasis="tertiary">
								<b>atencioncliente@volkswagen.es</b>
							</CTA>
							.
						</TextColor>
					</Text>
					<Text appearance={TokenTextAppearance.copy100}>
						<TextColor>
							Puedes consultar la información adicional en la{' '}
							<CTA tag="a" href="https://www.volkswagen.es/es/volkswagen-espana/politica-privacidad.html" target="_blank" emphasis="tertiary" >
								<b>política de privacidad</b>
							</CTA>
							.
						</TextColor>
					</Text>
				</Container>
			</PaddingWrapper>
		</Layout>
	);
};
