import axios, {AxiosResponse} from 'axios';
import {MolecularResponse, OneformBFFResponse} from 'src/types';
import {getMolecularUrl, getOneformBFFUrl, getOneformBFFApiKey, reportErrorSentry} from 'src/bff';

export const oneformBFFNdpsSend = async (
	formData: FormData,
	isPresubmit?: boolean,
): Promise<AxiosResponse<OneformBFFResponse>> => {
	if (isTest(formData, isPresubmit)) return {data: {status: {code: 200}}};

	try {
		const oneformBFFUrl = getOneformBFFUrl();
		const oneformBFFApiKey = getOneformBFFApiKey();
		const response = await axios.post(`${oneformBFFUrl}/ndps/responses`, formData, {
			headers: {
				'X-Api-Key': oneformBFFApiKey,
			},
		});

		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const oneformBFFYumiwiSend = async (
	formData: FormData,
	isPresubmit?: boolean,
): Promise<AxiosResponse<OneformBFFResponse>> => {
	if (isTest(formData, isPresubmit)) return {data: {status: {code: 200}}};

	try {
		const oneformBFFUrl = getOneformBFFUrl();
		const oneformBFFApiKey = getOneformBFFApiKey();
		const response = await axios.post(`${oneformBFFUrl}/ndps/responses/yumiwi`, formData, {
			headers: {
				'X-Api-Key': oneformBFFApiKey,
			},
		});

		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const dpsSend = async (formData: FormData, isPresubmit?: boolean): Promise<AxiosResponse<MolecularResponse>> => {
	if (isTest(formData, isPresubmit)) return {data: {content: {code: '200'}}};

	try {
		const MOLECULAR_URL = getMolecularUrl(); // Legacy
		const response = await axios.post(`${MOLECULAR_URL}/request-dps-send.php`, formData);
		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const yumiwiSend = async (
	formData: FormData,
	isPresubmit?: boolean,
): Promise<AxiosResponse<MolecularResponse>> => {
	if (isTest(formData, isPresubmit)) return {data: {content: {code: '200'}}};

	try {
		const MOLECULAR_URL = getMolecularUrl(); // Legacy
		const response = await axios.post(`${MOLECULAR_URL}/request-dps-send-yumiwi.php`, formData);
		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

export const raceSend = async (
	formData: FormData,
	isPresubmit?: boolean,
): Promise<AxiosResponse<MolecularResponse>> => {
	if (isTest(formData, isPresubmit)) return {data: {content: {code: '200'}}};

	try {
		const MOLECULAR_URL = getMolecularUrl(); // Legacy
		const response = await axios.post(`${MOLECULAR_URL}/request-race-callme.php`, formData);
		return response;
	} catch (error) {
		reportErrorSentry(error);
		return null;
	}
};

const isTest = (formData: FormData, isPresubmit?: boolean) => {
	const urlParams = new URLSearchParams(window.location.search);
	const test =
		urlParams.get('test') &&
		((isPresubmit && urlParams.get('test').includes('PRESUBMIT')) ||
			(!isPresubmit && urlParams.get('test').includes('SEND')));
	if (test) {
		let fields = [];
		for (const entry of formData.entries()) {
			fields[entry[0]] = entry[1];
		}
		console.log('%cOneform Test Send:', 'font-weight: 700', fields);
		return true;
	}
	return false;
};

export const motorflashSend = (formData: FormData): Promise<MolecularResponse> => {
	const MOLECULAR_URL = getMolecularUrl(); // Legacy

	return axios
		.post(`${MOLECULAR_URL}/request-motorflash-send.php`, formData)
		.then((response: AxiosResponse<MolecularResponse>) => response.data)
		.catch((error) => error);
};
