import {
	Breakpoints,
	Container,
	ContainerGutter,
	Layout,
	Spinner,
	styled,
	Text,
} from '@volkswagen-onehub/components-core';
import React from 'react';

const Content = styled.div`
	background-color: white;
	padding: 44px 31px;
	flex: auto;
	text-align: center;
	width: 100%;
	@media screen and (min-width: 560px) {
		padding: 52px 46px;
	}
	@media screen and (min-width: 960px) {
		padding: var(--size-grid001);
	}
`;

interface loadingComponentProps {
	copy?: string;
}

export function LoadingLayer(props: loadingComponentProps) {
	return (
		<Content>
			<LoadingComponent copy={props.copy} />
		</Content>
	);
}

function LoadingComponent(props: loadingComponentProps) {
	return (
		<Layout
			appearance={{
				[Breakpoints.default]: [
					{name: '.', columns: 2},
					{name: 'a', columns: 20},
					{name: '.', columns: 2},
				],
			}}
		>
			<Container gutter={ContainerGutter.static150} wrap={'always'}>
				<Spinner variant="large" />
				{props.copy ? <Text>{props.copy}</Text> : <Text>Confirmando tu cita</Text>}
			</Container>
		</Layout>
	);
}
