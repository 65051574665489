import {
	Breakpoints,
	Container,
	CTA,
	Layout,
	Text,
	TextAlignment,
	ContainerGutter,
	TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import {PaddingWrapper} from 'src/components';
import React from 'react';
import {useFormContext} from 'react-hook-form';
import {isGooglePlace, Suggest, Suggestion} from 'src/feature-app';
import {useLocateUser} from 'src/feature-app/hooks';
import {Installation, isDealerData} from 'src/types';

interface LocateUserProps {
	isFiltrosSuggest?: boolean;
}

export function LocateUser(props: LocateUserProps) {
	const {isFiltrosSuggest} = props;
	const {register} = useFormContext();
	const {
		value,
		setValue,
		suggestion,
		setSuggestion,
		icon,
		locationSuccess,
		setLocationSuccess,
		handleLocate,
		loading,
		locationDisabled,
		isLocating,
		onSuggestionSelectedFn,
	} = useLocateUser();

	const getSuggestionValue = (suggestion: Suggestion) => {
		if (!isGooglePlace(suggestion) && !isDealerData(suggestion)) {
			return (suggestion as Installation).VGED_CommercialName__c;
		} else {
			return suggestion.name;
		}
	};

	return (
		<>
			<Layout
				allowOverflowingContent
				appearance={{
					[Breakpoints.default]: [
						{name: '.', columns: 2},
						{name: 'a', columns: 20},
						{name: '.', columns: 2},
					],
					[Breakpoints.b960]: [
						{name: '.', columns: 3},
						{name: 'a', columns: 18},
						{name: '.', columns: 3},
					],
				}}
			>
				<PaddingWrapper>
					<Container wrap={'always'} gutter={ContainerGutter.static400}>
						<div style={{position: 'relative', overflow: 'visible'}}>
							<Suggest
								onSuggestionSelectedFn={onSuggestionSelectedFn}
								setSuggestion={setSuggestion}
								value={value}
								setValue={setValue}
								isFiltrosSuggest={isFiltrosSuggest ? isFiltrosSuggest : false}
								isLocating={isLocating}
								setLocationSuccess={setLocationSuccess}
							/>
						</div>
						<Container wrap={'always'}>
							<CTA
								tag="button"
								emphasis={locationSuccess ? 'primary' : 'secondary'}
								icon={icon}
								onClick={async (e) => {
									e.preventDefault();
									if (!locationSuccess) handleLocate();
								}}
								disabled={loading ? true : false}
								size="small"
							>
								Localiza mi posición actual
							</CTA>
							{locationDisabled ? (
								<div style={{paddingTop: '32px'}}>
									<Text textAlign={TextAlignment.left} appearance={TokenTextAppearance.copy100}>
										Tu localización está deshabilitada
									</Text>
								</div>
							) : null}
						</Container>
					</Container>
				</PaddingWrapper>
			</Layout>
			<input
				type="hidden"
				name="suggestion"
				value={suggestion ? getSuggestionValue(suggestion) : null}
				ref={register({required: true})}
			/>
		</>
	);
}
