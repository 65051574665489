import {
  Breakpoints,
  Container,
  ContainerPadding,
  Layout,
  LayoutConfiguration,
  styled,
  Text,
  TokenTextAppearance,
} from '@volkswagen-onehub/components-core';
import { Checkmark } from 'src/icons-core-imports';
import { Legales } from 'src/components';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import { TIME_FOR_OPACITY_MS, TIME_FOR_TRANSFORM_MS } from 'src/globals';
import { OneFormState, Steps } from 'src/types';
import { useTrackingManager } from 'src/feature-app/hooks/use-tracking-manager';
import { PreviousStep, CloseComponent, Navigation } from 'src/feature-app/Screen';
import { useOneFormContext } from '../OneForm';
import { css, keyframes } from 'styled-components';
import { useWindowHeight, useWindowWidth } from '@react-hook/window-size';
import { useShowOverflow } from 'src/feature-app/hooks';

const lineKeyframe = keyframes`
  0% {
    transform: rotate(-90deg);
  }
  60% {
    transform: rotate(45deg);
  }
  100% {
    transform: rotate(0deg);
  }
`;

const AnimatedCheckmark = styled.div`
  animation: ${lineKeyframe} 0.3s ease-in;
  svg {
    color: #001e50;
  }
`;

interface PaddingControllerProps {
  screenIndex?: number;
  multiStepScreenIndex?: number;
  showPreviousStep?: boolean;
  formHeight?: string;
  formMin?: boolean;
  isTrigger?: boolean;
  showOverflow?: boolean;
  alingItemsCenter?: boolean;
}

const ContentCotroller = styled.div<PaddingControllerProps>`
  width: 100%;
  min-height: calc(100vh - 140px);
  height: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: ${(props) => ( props.isTrigger ? 'hidden' : 'visible')};
  padding-bottom : 44px;;
  align-items: ${(props) => props.alingItemsCenter ? 'center' : null};
  @media all and (min-width: 560px) {
    min-height: calc(100vh - 92px);
  }
  @media all and (min-width: 960px) {
    min-height: calc(100vh - 100px);
  }
  @media all and (min-width: 1920px) {
    min-height: calc(100vh - 116px);
  }
`;


const FakePreviousStep = styled.div`
  height: 136px;
`;
const FakeClose = styled.div`
  height: 32px;
  margin-bottom: auto;
  width: 100%;
  @media all and (min-width: 960px) {
    margin-bottom: unset;
    position: absolute;
    top: 0;
  }
`;
interface PrevStepWrapperProps extends PaddingControllerProps {
  moveForward: boolean;
  distanceToMove: number;
  showPreviousStep: boolean;
}



const PrevStepWrapper = styled.div<PrevStepWrapperProps>`
  margin-left: ${(props) => (props.showPreviousStep ? 'var(--size-grid004)' : 'unset')};
  width: ${(props) => (props.showPreviousStep ? 'var(--size-grid014)' : 'unset')};
  display: flex;

  @media screen and (min-width: 1280px) {
    margin-left: ${(props) => (props.showPreviousStep ? 'var(--size-grid006)' : 'unset')};
    width: ${(props) => (props.showPreviousStep ? 'var(--size-grid010)' : 'unset')};
  }
  @media screen and (min-width: 1920px) {

    margin-left: ${(props) => (props.showPreviousStep ? 'var(--size-grid007)' : 'unset')};
    width: ${(props) => (props.showPreviousStep ? 'var(--size-grid009)' : 'unset')};
  }
  @media screen and (min-width: 2560px) {
    margin-left: ${(props) => (props.showPreviousStep ? 'var(--size-grid008)' : 'unset')};
    width: ${(props) => (props.showPreviousStep ? 'var(--size-grid007)' : 'unset')};
  }
  &.no-animation {
    opacity:1;
  }
  &.prev-step-exit-active {
    ${(props) =>
    props.moveForward
      ? css`
          transform: translate3d(0, -90%, 0);
          transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-out;
        `
      : css`
          opacity: 0.5;
          @media screen and (min-width: 960px) {
            transform: translate3d(0, 10vh, 0);
          }
          transition: all 300ms;
        `}
  }
  &.prev-step-exit-done {
    ${(props) =>
    !props.moveForward && props.distanceToMove
      ? css`
          width: 75%;
          transition: all 250ms;
          transform: translate3d(-4.17vw, ${props.distanceToMove}px, 0);
          * {
            font-size: var(--textappearances-headline350-fontsize);
            line-height: var(--textappearances-headline350-lineheight);
            letter-spacing: var(--textappearances-headline350-letterspacing);
            opacity: 1;
          }
        `
      : css`
          transform: translate3d(0, -90%, 0);
          transition: transform ${TIME_FOR_TRANSFORM_MS}, font-size ${TIME_FOR_TRANSFORM_MS},
            line-height ${TIME_FOR_TRANSFORM_MS}, letter-spacing ${TIME_FOR_TRANSFORM_MS},
            opacity ${TIME_FOR_OPACITY_MS} ease-out;
        `}
  }
`;

const ContentWrapper = styled.div<PaddingControllerProps>`
  width: 100%;
  height: 100%;
  overflow: visible;
`;

const TitleWrapper = styled.div<{ moveForward: boolean; distanceToTop: number }>`
  opacity: 0;
  &.no-animation {
    opacity: 1;
    > div {
      &:first-of-type {
        width: var(--size-grid020);
        @media all and (min-width: 960px) {
          width: var(--size-grid018);
        }
        @media all and (min-width: 1280px) {
          width: var(--size-grid014);
        }
        @media all and (min-width: 1920px) {
          width: var(--size-grid012);
        }
        @media all and (min-width: 2560px) {
          width: var(--size-grid010);
        }
      }
    }
  }
  &.title-enter {
    transform: translate3d(0%, -50%, 0);
    opacity: 0;
    width: 100% !important;
    @media screen and (min-width: 960px) {
      transform: ${(props) => (props.moveForward ? 'translate3d(0%, -50%, 0)' : 'unset')};
      opacity: ${(props) => (props.moveForward ? '0' : '1')};
    }
  }
  &.title-enter-done {
    width: 100% !important;
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-in;
  }
  &.title-exit {
    width: 100% !important;
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  &.title-exit-active {
    ${(props) =>
    props.moveForward
      ? css`
            opacity: 0.5;
            @media screen and (min-width: 960px) {
              transform: translate3d(var(--size-grid001), -10vh, 0);
              /* width previous step solo en forward move */
              width: var(--size-grid014) !important;
              * {
                font-size: var(--textappearances-headline300-fontsize);
                line-height: var(--textappearances-headline300-lineheight);
                letter-spacing: var(--textappearances-headline300-letterspacing);
              }
            }
            @media screen and (min-width: 1280px) {
              width: var(--size-grid010) !important;
            }
            @media screen and (min-width: 1600px) {
              transform: translate3d(var(--size-grid001), -15vh, 0);
            }
            @media screen and (min-width: 1920px) {
              transform: translate3d(var(--size-grid001), -15vh, 0)};
              width: var(--size-grid009) !important;
            }
            @media screen and (min-width: 2560px) {
              transform: translate3d(var(--size-grid001), -15vh, 0)};
              width: var(--size-grid007) !important;
            }
            transition: all 350ms;
          `
      : css`
          opacity: 0;
          transform: translate3d(0, 350%, 0);
          transition: all ${TIME_FOR_TRANSFORM_MS};
        `}
  }
  &.title-exit-done {
    /* desktop */
    ${(props) =>
    props.moveForward && props.distanceToTop
      ? css`
          opacity: 0.2;
          * {
            font-size: var(--textappearances-headline300-fontsize);
            line-height: var(--textappearances-headline300-lineheight);
            letter-spacing: var(--textappearances-headline300-letterspacing);
          }
          transform: translate3d(var(--size-grid001), -${props.distanceToTop}px, 0);
          transition: all 300ms;
          @media screen and (min-width: 960px) {
            width: var(--size-grid014) !important;
          }
          @media screen and (min-width: 1280px) {
            width: var(--size-grid010) !important;
          }
          @media screen and (min-width: 1920px) {
            width: var(--size-grid009) !important;
          }
          @media screen and (min-width: 2560px) {
            width: var(--size-grid007) !important;
          }
        `
      : css`
          opacity: 0;
          width: inherit !important;
          transform: translate3d(0, 45vh, 0);
          transition: all 300ms;
          @media screen and (min-width: 960px) {
            transform: translate3d(0, 350%, 0);
            transition: transform ${TIME_FOR_TRANSFORM_MS}, font-size ${TIME_FOR_TRANSFORM_MS},
              line-height ${TIME_FOR_TRANSFORM_MS}, letter-spacing ${TIME_FOR_TRANSFORM_MS},
              opacity ${TIME_FOR_OPACITY_MS} ease-out;
          }
        `}
  }
`;

const MobileTitleWrapper = styled.div<{ moveForward: boolean; distanceToTop: number }>`
  width: var(--size-grid020) !important;
  padding-top: 52px;
  &.title-enter {
    transform: translate3d(0%, -50%, 0);
    opacity: 0;
  }
  &.title-enter-done {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity ${TIME_FOR_OPACITY_MS} ease-in;
  }
  &.title-exit {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  &.title-exit-active {
    ${(props) =>
    props.moveForward
      ? css`
            opacity: 0.1;
            transition: all 200ms;
          `
      : css`
            opacity: 0;
            transform: translate3d(0, 350%, 0);
            transition: all ${TIME_FOR_TRANSFORM_MS};
          `}
  }
  &.title-exit-done {
    opacity: ${(props) => (props.moveForward ? '0.1' : '0')};
    /* mobile */
    transform: ${(props) => (props.moveForward ? 'translate3d(0, -25vh, 0)' : 'translate3d(0, 45vh, 0)')};
    transition: all 300ms;
  }
`;

const FieldsWrapper = styled.div<{ opacity: string }>`
  opacity: ${(props) => props.opacity};
  &.fields-enter {
    transform: translate3d(0, 25%, 0);
    opacity: 0;
  }
  &.fields-enter-active {
    transform: translate3d(0, 0, 0);
    opacity: 1;
    transition: transform ${TIME_FOR_TRANSFORM_MS}, opacity 300ms ease-in;
  }
  &.fields-exit {
    opacity: 0;
  }
  &.fields-exit-active {
    opacity: 0;
  }
`;
interface ScreenProps {
  title: JSX.Element;
  fields: JSX.Element;
  key: number;
  avoidTrackFormStepLoad?: boolean;
  thankYouPage?: boolean;
}

const defaultLayout: LayoutConfiguration = {
  [Breakpoints.default]: [
    { name: '.', columns: 2 },
    { name: 'a', columns: 20 },
    { name: '.', columns: 2 },
  ],
  [Breakpoints.b960]: [
    { name: '.', columns: 3 },
    { name: 'a', columns: 18 },
    { name: '.', columns: 3 },
  ],
  [Breakpoints.b1280]: [
    { name: '.', columns: 5 },
    { name: 'a', columns: 14 },
    { name: '.', columns: 5 },
  ],
  [Breakpoints.b1920]: [
    { name: '.', columns: 6 },
    { name: 'a', columns: 12 },
    { name: '.', columns: 6 },
  ],
  [Breakpoints.b2560]: [
    { name: '.', columns: 7 },
    { name: 'a', columns: 10 },
    { name: '.', columns: 7 },
  ],
};

export function FullScreen(props: ScreenProps) {
  const { title, fields, key, avoidTrackFormStepLoad, thankYouPage } = props;
  const { formInfo, formData } = useSelector((state: OneFormState) => state);
  const {
    multiStepScreenIndex,
    screenIndex,
    triggerExitAnimation,
    showFinalScreen,
    numberOfScreens,
    formEnded,
    alingItemsCenter,
    deleteCloseAndPrevious
  } = formInfo;
  const trackingManager = useTrackingManager();
  const {
    distanceToMove,
    setDistanceToMove,
    moveForward,
    nextScreenIndex,
    nextMultiStepScreenIndex,
    showPreviousStep,
  } = useOneFormContext();

  //Si se necesita que el overflow sea visible. Selectsno nativos, suggests, etc. Añadir en la definicion del step showOverflow.

  const { showOverflow } = useShowOverflow(false);

  //Añadir link pdf concursos (hay que ir al componente Legales para setear la url)
  const [ showBasesLegales, setShowBasesLegales ] = useState(false);

  const [isConfiracionPage, setIsConfirmacionPage] = useState(false);

  useEffect(() => {

    if(formData.fields.formName ==='adhoc-2021-eurocopa') {
      setShowBasesLegales(true);
    }

    if (showFinalScreen) {
      setIsConfirmacionPage(false);
    }

    if (screenIndex === numberOfScreens - 1) {
      if( formData.fields.formName === 'cem-venta' || formData.fields.formName === 'cem-posventa' ){
        setIsConfirmacionPage(false);
      } else {
        setIsConfirmacionPage(true);
      }
    } else {
      setIsConfirmacionPage(false);
    }
  }, [screenIndex, showFinalScreen]);

  // calc height stuff
  const [initialHeight, setInitialHeight] = useState(null);
  const [isTrigger, setIsTrigger] = useState<boolean>(false);
  const viewportHeight = useWindowHeight();
  const viewportWidth = useWindowWidth();

  const content = useRef(null);
  const prevStep = useRef(null);

  const [currentRef, setCurrentRef] = useState(null); // optimizar ocn useMemo

  // animation stuff
  const [showFields, setShowFields] = useState(false);
  const [showTitle, setShowTitle] = useState(false);
  const [opacity, setOpacity] = useState('0');

  // variables para animacion entre steps
  const [distanceToTop, setDistanceToTop] = useState<number>(0);
  const titleRef = useRef<any>(null);
  const contentRef = useRef<any>(null);

  // navigation stuff
  const [formClicked, setFormClicked] = useState(false);

  useEffect(() => {
    if (!formClicked) {
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
  }, [formClicked]);

  // usar useLayoutEffect para asegurar que los cálculos de distancias se hacen antes de un nuevo render del contenido
  useEffect(() => {
    setTimeout(() => {
      if (titleRef.current && contentRef.current) {
        const titleLength = titleRef.current.innerText.length;
        // titleRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
        /*
            extra height en breakpoints grandes y dependiendo de la longitud del título, que afecta a la animación
            desconozco por qué ocurre, pero no depende del viewport height, sino del viewport width
          */
        if (window.matchMedia('(min-width: 1600px)').matches && contentRef) {
          const extraHeight = titleLength < 60 ? (titleLength < 53 ? 8 : 18) : 36;
          setDistanceToTop(titleRef.current.offsetTop + extraHeight);
        } else {
          const extraHeight = titleLength < 53 ? 18 : 0;
          // setDistanceToTop(titleRef.current.getBoundingClientRect().top - extraHeight);
          setDistanceToTop(
            titleRef.current.getBoundingClientRect().top -
              contentRef.current.getBoundingClientRect().top +
              32 -
              extraHeight
          );
        }
        /*
            guardar distancia del título a la parte superior del contenedor padre en un array de distancias
            para saber la distancia que tiene que recorrer el previousStep si la navegación va para atrás
          */
        if ((screenIndex === 0 && nextScreenIndex === null) || screenIndex + 1 > distanceToMove.length) {
          const extraHeight = titleLength < 53 ? 18 : 0;
          setDistanceToMove((distanceToMove) => [
            ...distanceToMove,
            titleRef.current.getBoundingClientRect().top -
              contentRef.current.getBoundingClientRect().top +
              32 -
              extraHeight,
          ]);
        } else if (
          screenIndex + 1 <= distanceToMove.length &&
          nextMultiStepScreenIndex !== 1 &&
          nextMultiStepScreenIndex !== 2
        ) {
          const arrDistances = distanceToMove;
          const extraHeight = titleLength < 53 ? 18 : 0;
          arrDistances[screenIndex] =
            titleRef.current.getBoundingClientRect().top -
            contentRef.current.getBoundingClientRect().top +
            32 -
            extraHeight;
          setDistanceToMove(arrDistances);
        }
      }
    }, 0);
  }, [titleRef]);

  useEffect(() => {
    if (triggerExitAnimation) {
      if (!formEnded) {
        setShowTitle(false);
        setShowFields(false);
      }
    }
  }, [triggerExitAnimation]);

  useEffect(() => {
    // Ni en la primera carga ni en la thank you page se tiene que disparar este evento.
    // El flag isFirstStep solo lo necesitamos en la primera carga, después si que se tiene que disparar el evento.

    if (formInfo.step?.isFirstStep || avoidTrackFormStepLoad || (formInfo.stepsHistory?.length === 1 && (!multiStepScreenIndex || multiStepScreenIndex === 0))) {
      if(formInfo.step) formInfo.step.isFirstStep = false;
      return;
    }
    trackingManager.trackFormStepLoad(
      {
        // contentId: step.title
      },
      {
        FormStart: false,
      }
    );
  }, [title]);

  useEffect(() => {
    if (window.matchMedia('(max-width: 960px)').matches && contentRef) {
      contentRef.current.scrollTo({ top: 0 });
    }
    setShowTitle(true);
  }, []);

  //Que en la zona del mapa no vuelva a lanzar las animaciones
  const [ stopAnimation, setStopAnimation ] = useState(false);

  useEffect(()=>{

    if(
      formData.fields.formName === 'no_compra-no_tengo'
      && screenIndex === 4
      && multiStepScreenIndex === 0
      || formData.fields.formName === 'no_compra-no_tengo'
      && screenIndex === 5
      && multiStepScreenIndex === 0
    ) {
      setStopAnimation(true);
    }

  }, [screenIndex]);

  const [ hideCloseAndPrevious, setHideCloseAndPrevious ] = useState(false);

  useEffect(()=>{
    if(deleteCloseAndPrevious){
      if(
        formData.fields.formName === 'lead-test_drive-configure' && multiStepScreenIndex === 0 && screenIndex === 0
        || formData.fields.formName === 'lead-presupuesto-configure' && multiStepScreenIndex === 0 && screenIndex === 0
        || formData.fields.formName === 'lead-mas_informacion-configure' && multiStepScreenIndex === 0 && screenIndex === 0
        || formData.fields.formName === 'lead-cita-configure' && multiStepScreenIndex === 0 && screenIndex === 0
      ) {
        setHideCloseAndPrevious(true);
      } else {
        setHideCloseAndPrevious(false);
      }
    } else {
      setHideCloseAndPrevious(false);
    }

  }, [screenIndex]);

  const [ alingContentCenter, setAlingContentCenter ] = useState(false);

  useEffect(()=>{
    if(viewportWidth < 960 || alingItemsCenter=== true) {
      setAlingContentCenter(true);
    } else {
      setAlingContentCenter(false);
    }
  }, [screenIndex]);


  return (
    <>
      <ContentCotroller
        className='content-controller-fullScreen'
        ref={contentRef}
        isTrigger={isTrigger}
        showOverflow = {showOverflow}
        alingItemsCenter={alingContentCenter}
      >
        {showFinalScreen || hideCloseAndPrevious || thankYouPage ? null : screenIndex === 0 ? (
          <FakeClose />
        ) : (
          <CloseComponent notALayer={true} colorTheme='main' />
        )}

        {showFinalScreen || hideCloseAndPrevious || thankYouPage ? null : viewportWidth >= 960 && !showPreviousStep ? (
          <FakePreviousStep />
        ) : viewportWidth >= 960 ?
         (
          <CSSTransition timeout={moveForward ? 400 : 200} in={showTitle} classNames='prev-step'>
            <PrevStepWrapper
              className={stopAnimation ?  'no-animation' : 'prev-step-controller'}
              screenIndex={screenIndex}
              multiStepScreenIndex={multiStepScreenIndex}
              moveForward={moveForward}
              distanceToMove={distanceToMove[screenIndex - 1]}
              showPreviousStep={showPreviousStep}
              ref={prevStep}
            >
              <PreviousStep notALayer={true} stopAnimation={stopAnimation}/>
            </PrevStepWrapper>
          </CSSTransition>
        ) : null}

        <ContentWrapper
          ref={content}
          className='content-wrapper'
          screenIndex={screenIndex}
          showPreviousStep={showPreviousStep}
          multiStepScreenIndex={multiStepScreenIndex}
        >
          <Layout
            allowOverflowingContent
            appearance={defaultLayout}
          >
            <>
              {showFinalScreen ? (
                <Container padding={{ bottom: ContainerPadding.dynamic0050 }}>
                  <AnimatedCheckmark className='animated-checkmark'>
                    <Checkmark variant='default' />
                  </AnimatedCheckmark>
                </Container>
              ) : null}

              {
                viewportWidth < 960 && title ? (
                  <CSSTransition
                  timeout={moveForward ? 200 : 400}
                  in={showTitle}
                  key={key}
                  classNames='title'
                  onEntered={() => {
                    setTimeout(() => {
                      setShowFields(true);
                    }, 100);
                  }}
                  onExited={() => {}}
                  >
                    <MobileTitleWrapper
                      className={stopAnimation ?  'no-animation' : 'title-wrapper'}
                      moveForward={moveForward}
                      distanceToTop={distanceToTop}
                      ref={titleRef}
                    >
                      <Text appearance={TokenTextAppearance.headline300}>{title}</Text>
                    </MobileTitleWrapper>
                  </CSSTransition>
                ) : (
                <CSSTransition
                  timeout={moveForward ? 200 : 400}
                  in={showTitle}
                  key={key}
                  classNames='title'
                  onEntered={() => {
                    setTimeout(() => {
                      setShowFields(true);
                    }, 100);
                  }}
                  onExited={() => {}}
                >
                  <TitleWrapper
                    className={stopAnimation ?  'no-animation' : 'title-wrapper'}
                    moveForward={moveForward}
                    distanceToTop={distanceToTop}
                    ref={titleRef}
                  >
                    <Text appearance={TokenTextAppearance.headline300}>{title}</Text>
                  </TitleWrapper>
                </CSSTransition>
                )
              }
            </>
          </Layout>

          <CSSTransition
            timeout={300}
            in={showFields}
            key={key}
            classNames='fields'
            onEntered={() => {
              setOpacity('1');
            }}
            onExited={() => {
              setOpacity('0');
            }}
          >
            <FieldsWrapper opacity={opacity} >{fields}</FieldsWrapper>
          </CSSTransition>
        </ContentWrapper>
      </ContentCotroller>
      {showFinalScreen ? null : (
        <>
          {isConfiracionPage ? (
            <Layout
              allowOverflowingContent
              appearance={{
                [Breakpoints.default]: [
                  { name: '.', columns: 2 },
                  { name: 'a', columns: 20 },
                  { name: '.', columns: 2 },
                ],
              }}
            >
              <Legales showBasesLegales={showBasesLegales} />
            </Layout>
          ) : null}
        </>
      )}
    </>
  );
}
